import React, { ReactElement } from 'react'

import { StaticQuery, graphql } from 'gatsby'
import { Layout } from 'antd'

import AppHeader from './appHeader'

const { Footer, Content } = Layout

export default (props): ReactElement => {
  const { children } = props

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data): ReactElement => (
        <Layout>
          {false && <AppHeader siteTitle={data.site.siteMetadata.title} />}
          <Content>{children}</Content>
          <Footer
            style={{
              background: 'none',
              borderTop: '1px solid #eee',
              color: '#333',
              backgroundImage: 'none',
            }}
          >
            &copy; {new Date().getFullYear()} KŁOSY + STRM
          </Footer>
        </Layout>
      )}
    />
  )
}
