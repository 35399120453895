import React from 'react'
import { Link } from 'gatsby'
import { Layout } from 'antd'

const { Header } = Layout

interface HeaderProps {
  siteTitle: string
}

const AppHeader: React.FunctionComponent<HeaderProps> = ({
  siteTitle,
}): React.ReactElement => (
  <Header>
    <h1>
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
      >
        {siteTitle}
      </Link>
    </h1>
  </Header>
)

export default AppHeader
